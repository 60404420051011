import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container } from './ui';
import TeamCard from './TeamCard';

const StyledTeamCardListing = styled.section`
  ${sectionMargins()};
`;

const StyledInner = styled.div`
  display: grid;
  gap: 20px;

  ${minBreakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.large`
    gap: 25px;
  `}
`;

const TeamCardListing = ({ items }) =>
  items.length > 0 && (
    <StyledTeamCardListing>
      <Container>
        <StyledInner>
          {items.map(({ id, title, slug, role, image }) => (
            <TeamCard
              key={id}
              name={title}
              slug={slug}
              role={role}
              image={image}
            />
          ))}
        </StyledInner>
      </Container>
    </StyledTeamCardListing>
  );

export default TeamCardListing;
